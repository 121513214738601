// assets
import { IconEqual, IconEqualNot, IconBusinessplan } from '@tabler/icons';
import ScaleIcon from '@mui/icons-material/Scale';
import ListIcon from '@mui/icons-material/List';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import StorefrontIcon from '@mui/icons-material/Storefront';

// constant
const icons = {
    IconEqual,
    IconBusinessplan,
    IconEqualNot,
    ScaleIcon,
    ListIcon,
    CompareArrowsIcon,
    StorefrontIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const opciones = {
    id: 'opciones',
    title: 'Opciones',
    type: 'group',
    children: [
        {
            id: 'homologaciones',
            title: 'Homologaciones',
            type: 'collapse',
            icon: icons.CompareArrowsIcon,

            children: [
                {
                    id: 'homologados',
                    title: 'Homologados',
                    type: 'item',
                    url: '/homologaciones/homologados',
                    target: false,
                    icon: icons.IconEqual
                },
                {
                    id: 'nohomologados',
                    title: 'No homologados',
                    type: 'item',
                    url: '/homologaciones/no-homologados',
                    target: false,
                    icon: icons.IconEqualNot
                }
            ]
        },
        {
            id: 'maestro',
            title: 'Maestro Productos',
            type: 'item',
            icon: icons.ListIcon,
            url: '/maestro',
            target: false
        },
        {
            id: 'distribuidoras',
            title: 'Distribuidoras',
            type: 'item',
            icon: icons.StorefrontIcon,
            url: '/distribuidoras',
            target: false
        }
    ]
};

export default opciones;
